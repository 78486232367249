

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2efef;
  height: 100%;  
}


.skills {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  width: 65%;
}

#info {
  text-align: justify;
  text-align-last: center;
}