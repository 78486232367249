.navigation {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.links {
    color: black;
    padding: 1rem;
    font-size: 2rem;
    text-decoration: none;
    font-weight: normal;
    transition-property: all 2s ease-in;
}

.links:hover {
    font-weight: bold;
    text-decoration: underline overline;
    text-underline-offset: 1rem;    
}