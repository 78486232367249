.allProjects {
    background-color: whitesmoke;
    padding: 2rem;
    border: 0.5px solid black;
    display: flex;
    flex-direction: column;
    width: 60%;
}


.projTitle {
    font-weight: bold;
}

.projDesc {
    background-color: rgb(227, 226, 226);
    padding: 10px;
    line-height: 1.7;
}

.techStack {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-around;
    background-color: rgb(227, 226, 226);
    border: 1px solid black;
}

.projLink {
    text-decoration: underline;
}


#stacks {
    padding: 10px;
}